<template>
  <div class="account-my-templates-tab">
    <div class="account-page-tab__header">
      <h2>
        Мои шаблоны
      </h2>
      <div class="account-page-tab__settings">
        <esmp-button
          class="account-page-tab__settings-button"
          view="function"
          icon="24-settings"
          title="Включить режим сортировки"
          @click.stop="toggleTemplatesSortMode"
        />
        <h5
          v-if="isDraggable"
          class="account-page-tab__settings-hint"
        >
          Перемещение возможно в рамках одной страницы
        </h5>
      </div>
    </div>

    <template v-if="mappedTemplates.length">
      <div class="account-my-templates-tab__items" :key="templatesUpdateKey">
        <draggable
          v-model="mappedTemplates"
          :options="{ disabled: !isDraggable }"
          handle=".account-page-tab__item--draggable"
        >
          <additional-documents-item
            v-for="template in mappedTemplates"
            :key="`template_${template.id}`"
            :title="template.title"
            :url="template.url"
            :class="[
              'account-page-tab__item',
              {
                'account-page-tab__item--draggable': isDraggable,
              }
            ]"
          >
            <template v-slot:action-buttons>
              <esmp-icon
                name="edit"
                class="additional-documents-item__action-button"
                @click.prevent.native="showEditTemplateModal(template.id)"
              />

              <esmp-icon
                name="trash"
                class="additional-documents-item__action-button"
                @click.prevent.native="showDeleteTemplateModal(template.id)"
              />
            </template>
          </additional-documents-item>
        </draggable>
      </div>

      <div class="account-page-tab__pagination">
        <esmp-pagination-adaptive
          :current-page.sync="currentPage"
          :page-count="pagesCount"
        />
      </div>

      <esmp-modal
        v-model="deleteTemplateModalShowed"
        width="360"
        title="Подтвердите действие!"
        @on-cancel="closeDeleteTemplateModal"
      >
        <p>Уверены, что хотите удалить шаблон?</p>

        <div slot="footer">
          <esmp-button size="small" @click="deleteTemplate">
            Удалить
          </esmp-button>

          <esmp-button
            size="small"
            view="outline"
            @click="closeDeleteTemplateModal"
          >
            Отмена
          </esmp-button>
        </div>
      </esmp-modal>

      <esmp-modal
        v-model="editTemplateModalShowed"
        width="360"
        title="Переименование шаблона"
        @on-cancel="closeEditTemplateModal"
      >
        <esmp-input v-model="activeTemplateTitle" label="Имя шаблона" />

        <div slot="footer">
          <esmp-button size="small" @click="renameTemplate">
            Переименовать
          </esmp-button>

          <esmp-button
            size="small"
            view="outline"
            @click="closeEditTemplateModal"
          >
            Отмена
          </esmp-button>
        </div>
      </esmp-modal>
    </template>

    <filler-block
      v-else
      is-big
      icon="empty-templates"
      :icon-width="251"
      title="Мои шаблоны"
      subtitle="Здесь будут храниться ваши шаблоны.
        Шаблоны создаются из уже заполненной заявки.
        Используйте шаблоны для часто используемых однотипных заявок."
    />

    <esmp-loader v-if="loading" fix />
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapState, mapActions } from 'vuex';
import { AdditionalDocumentsItem } from '@/components/additional-documents';
import FillerBlock from '@/components/filler-block';
import { TEMPLATES_PER_PAGE } from '@/constants/account-page';
import { getPagesCount, getPaginatedArray } from '@/utils/pagination';
import { getCreateTicketPath } from '@/helpers/pathGenerator';
import Hub from '@/plugins/event-hub';

export default {
  name: 'AccountMyTemplatesTab',

  components: { FillerBlock, AdditionalDocumentsItem, draggable },

  data() {
    return {
      loading: false,
      deleteTemplateModalShowed: false,
      editTemplateModalShowed: false,
      activeTemplateId: null,
      activeTemplateTitle: null,
      templatesUpdateKey: 0,
      currentPage: 1,
      isDraggable: false,
    };
  },

  computed: {
    ...mapState('userTemplates', ['templates']),

    mappedTemplates: {
      get() {
        const templates = this.templates.map((item) => ({
          ...item,
          id: item.id,
          title: item.name,
          url: getCreateTicketPath(item.serviceId, item.sourceSystem, item.id),
        }));

        return getPaginatedArray(templates, this.currentPage, TEMPLATES_PER_PAGE);
      },
      set(val) {
        const sortedTemplates = this.templates
          .toSpliced((this.currentPage - 1) * TEMPLATES_PER_PAGE, val.length, ...val)
          .map((item, index) => ({
            ...item,
            sortOrder: index,
          }));
        this.updateSortOrderTemplates(sortedTemplates);
      },
    },

    pagesCount() {
      return getPagesCount(this.templates.length, TEMPLATES_PER_PAGE);
    },
  },

  methods: {
    ...mapActions('userTemplates', ['updateSortOrderTemplates']),
    showEditTemplateModal(templateId) {
      this.activeTemplateId = templateId;
      this.activeTemplateTitle = this.mappedTemplates.find((item) => item.id === templateId)?.title;
      this.editTemplateModalShowed = true;
    },

    closeEditTemplateModal() {
      this.activeTemplateId = null;
      this.activeTemplateTitle = null;
      this.editTemplateModalShowed = false;
    },

    showDeleteTemplateModal(templateId) {
      this.activeTemplateId = templateId;
      this.deleteTemplateModalShowed = true;
    },

    closeDeleteTemplateModal() {
      this.activeTemplateId = null;
      this.deleteTemplateModalShowed = false;
    },

    async renameTemplate() {
      this.loading = true;

      await this.$API.userTemplates.updateUserTemplateName(this.activeTemplateId, this.activeTemplateTitle);
      this.closeEditTemplateModal();

      Hub.$emit('account-my-templates-changed');

      this.loading = false;
    },

    async deleteTemplate() {
      this.loading = true;

      await this.$API.userTemplates.deleteUserTemplate(this.activeTemplateId);
      this.closeDeleteTemplateModal();

      Hub.$emit('account-my-templates-changed');

      this.loading = false;
    },

    toggleTemplatesSortMode() {
      this.isDraggable = !this.isDraggable;
    },
  },

  watch: {
    mappedTemplates() {
      this.templatesUpdateKey += 1;
    },
  },
};
</script>

<style lang="scss">
.account-my-templates-tab {
  .additional-documents-item {
    background: $color-white;
  }
}
</style>
